<template>
  <canvas id="restingHeartRateChart" width="400" height="300"></canvas>
</template>

<script>
import Chart from 'chart.js'
import dayjs from "dayjs";

export default {
  name: "IndexChart",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    const ctx = document.getElementById('restingHeartRateChart')
    const items = [...this.items].reverse()

    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: items.map((item, key) => {
          if (key === 0 || key + 1 === items.length) {
            return dayjs(item.created_at).format('DD.MM.YYYY')
          } else {
            return ''
          }
        }),
        datasets: [{
          label: '',
          data: items.map(item => {
            return item.value
          }),
          fill: false,
          pointRadius: 0,
          borderWidth: 1,
          borderColor: `rgba(167, 168, 169, 0.75)`
        }]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        }
      }
    })
  }
}
</script>