<template>
  <ion-item>
    <ion-label v-text="item.value"/>
    <ion-note slot="end" v-text="displayDate"></ion-note>
  </ion-item>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "IndexItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    displayDate() {
      return dayjs(this.item.created_at).format('DD.MM.YYYY HH:mm')
    }
  }
}
</script>