<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push({name: 'MyProfile'})"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.resting-heart-rate')"></ion-title>
        <ion-buttons slot="end">
          <ion-button @click="actionAdd">
            <ion-icon :icon="addOutline" color="primary"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <template v-if="userInstance.resting_heart_rate > 0 && items.length > 0">
        <div class="rhr-container">
          <p>{{ __('interface.your-resting-heart-rate') }}</p>
          <h1>{{ userInstance.resting_heart_rate }}</h1>
        </div>
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>{{ __('interface.measurement-graph') }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <index-chart v-if="!loadingState && items.length >= 2" :items="items"/>
            <p v-else v-text="__('interface.no-data')"></p>
          </ion-card-content>
        </ion-card>
        <ion-list-header v-text="__('interface.list-of-measurements')"></ion-list-header>
        <ion-list>
          <index-item v-for="(item, key) in items" :key="key" :item="item"/>
          <ion-item v-if="items.length <= 0">
            <ion-label v-text="__('interface.no-results')"></ion-label>
          </ion-item>
        </ion-list>
      </template>
      <template v-else>
        <div class="ion-margin-horizontal">
          <h1>{{ __('interface.rhr-alert.title') }}</h1>
          <p>{{ __('interface.rhr-alert.content') }}</p>
        </div>
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  actionSheetController,
  IonCardContent, IonCardHeader, IonCardSubtitle,
  IonIcon,
  IonPage,
  loadingController
} from "@ionic/vue";
import {addOutline} from 'ionicons/icons';
import {API} from "@/utils/apiWrapper";
import IndexItem from "./IndexItem";
import IndexChart from "./IndexChart";
import AuthService from "../../AuthService";

export default {
  name: "RestingHeartRate",
  mixins: [AuthService],
  components: {IndexChart, IndexItem, IonPage, IonIcon, IonCardContent, IonCardHeader, IonCardSubtitle},
  data() {
    return {
      userInstance: {},
      loadingState: true,
      loading: null,
      items: []
    }
  },
  methods: {
    async actionAdd() {
      const actionSheet = await actionSheetController.create({
        header: this.__('interface.options'),
        buttons: [
          {
            text: this.__('interface.measurement'),
            handler: () => this.$router.push({name: 'MyProfile/RestingHeartRate/Measurement'})
          },
          {
            text: this.__('interface.manually'),
            handler: () => this.$router.push({name: 'MyProfile/RestingHeartRate/Manually'})
          },
          {
            text: this.__('interface.cancel'),
            role: 'cancel'
          }
        ]
      });
      return actionSheet.present();
    }
  },
  async ionViewWillEnter() {
    this.loadingState = true
    this.loading = await loadingController.create({
      message: this.__('interface.loading'),
    });
    await this.loading.present();
  },
  async ionViewDidEnter() {
    this.userInstance = await this.user();
    await API.get('TheSpottApi', '/me-rhr', {}).then(response => {
      this.items = response.data ?? []
    }).finally(async () => {
      this.loadingState = false
      await this.loading.dismiss();
    })
  },
  setup() {
    return {
      addOutline
    }
  }
}
</script>

<style lang="scss" scoped>
.rhr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
  }
}
</style>